.topic-group-main {
  display: grid;
  grid-template-areas:
    " tg-nav "
    " tg-content-main ";
  grid-template-rows: 50px minmax(0, auto);
  grid-template-columns: 1fr;
}
.topic-group {
  display: grid;
  grid-template-areas:
    " tg-bar"
    " tg-content ";
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr;
}

.tg-content-main {
  grid-area: tg-content-main;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;
  grid-gap: 10px;

  margin: 26px 20px 20px 20px;
  padding: 10px;

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.tg-content {
  grid-area: tg-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;
  grid-gap: 10px;

  margin: 20px;
  padding: 20px;

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.tg-nav {
  grid-area: tg-nav;
  padding: 10px;
  margin: 0;
  background: var(--cs1-red-light);
}

.tg-bar-main {
  display: none;
  grid-area: tg-bar-main;
  background: var(--iah-grey-dark);
}
.tg-bar {
  grid-area: tg-bar;
  background: var(--iah-grey-dark);
}

@media (min-width: 600px) {
  .tg-content-main {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .tg-content {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .topic-group-main {
    grid-template-areas:
      " tg-bar-main tg-bar-main tg-bar-main     tg-bar-main "
      " .           tg-nav      tg-content-main . ";
    grid-template-rows: 40px minmax(0, auto);
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }
  .topic-group {
    grid-template-areas:
      " tg-bar tg-bar     tg-bar     tg-bar "
      " .      tg-content tg-content . ";
    grid-template-rows: 40px auto;
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }
  .tg-bar-main {
    display: inline;
  }
  .tg-nav {
    padding: 10px 10px 10px 30px;
  }
}
